<template>
  <v-app id="inspire">
    <div class="mr-4">
      <div id="innerContent" class="ml-4 mr-4">
        <v-row>
          <v-col>
            <div class="mt-4 mb-2 d-flex flex-column align-left">
              <div class="d-flex flex-row" style="height: 50px !important">
                <img height="50" width="50" src="../assets/gamma_logo.svg" />

                <h2 class="display-1 ml-4">
                  Gamma
                  <v-chip color="primary" class="font-weight-bold" small
                    >v2.2</v-chip
                  >
                </h2>
              </div>
            </div>
            <v-divider> </v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="(metric, i) in homeMetrics"
            :key="i"
            cols="12"
            sm="12"
            md="6"
            lg="6"
          >
            <div class="home-info-box" @click="$router.push(metric.to)">
              <div class="d-flex flex-row align-left">
                <div class="mx-4 display-2">
                  {{ metric.metric }}
                  <v-icon size="50px" color="rgb(255,255,255,0.4)" class="mb-2">
                    {{ metric.icon }}
                  </v-icon>
                </div>
              </div>
              <span class="mx-4"> {{ metric.name }}</span>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="6">
            <ChartX />
          </v-col>
          <v-col cols="6">
            <ChartX />
          </v-col>
        </v-row> -->
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.text-grad {
  background: -webkit-linear-gradient(
    180deg,
    rgb(14, 106, 2) 2%,
    rgb(60, 255, 0) 67%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-info-box:hover {
  background: -webkit-linear-gradient(
    180deg,
    rgb(169, 255, 158) 2%,
    rgb(60, 255, 0) 67%
  );
}
.home-info-box {
  position: relative;
  border-radius: 10px;
  color: white;
  overflow: hidden; /* Ensure the pseudo-element does not exceed the border-radius */
  transition: color 0.5s ease; /* Transition for text color */
}

.home-info-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-linear-gradient(
    326deg,
    rgba(37, 2, 106, 1) 2%,
    rgba(33, 150, 243, 1) 67%
  );
  z-index: 0;
  transition: opacity 0.5s ease; /* Transition for the pseudo-element's opacity */
}

.home-info-box:hover::before {
  opacity: 0; /* Fully transparent on hover */
}

.home-info-box:hover {
  color: black;
}

/* Additional styles to ensure the content of .home-info-box is visible above the pseudo-element */
.home-info-box * {
  position: relative;
  z-index: 1;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  mounted() {
    document.title = "Gamma | Gestión Financiera";
    this.$store.dispatch("getHomepageMetrics");
  },
  // components: { ChartX },
  computed: {
    ...mapGetters(["homepageMetrics"]),
    homeMetrics() {
      return [
        {
          name: "Campañas",
          to: "/abstract-campaign",
          icon: "mdi-calendar",
          metric: this.homepageMetrics.campaigns,
        },
        {
          name: "Borradores",
          to: "/draft-engagement",
          icon: "mdi-pencil",
          metric: this?.homepageMetrics?.draft_engagements,
        },
        {
          name: "Establecimientos",
          to: "/establishment",
          icon: "mdi-home",
          metric: this?.homepageMetrics?.establishments,
        },
        {
          name: "Insumos/Servicios",
          to: "/cost-items",
          icon: "mdi-crop",
          metric: this?.homepageMetrics?.direct_slice_cost_items,
        },
        {
          name: "Costos Toneladas",
          to: "/other-ton-cost-items",
          icon: "mdi-weight",
          metric: this?.homepageMetrics?.ton_cost_items,
        },
        {
          name: "Politicas Financieras",
          to: "/financial-policy",
          icon: "mdi-file-document",
          metric: this?.homepageMetrics?.financial_policies,
        },
      ];
    },
  },
  methods: {},

  data() {
    return {};
  },
};
</script>
